<template>
  <div class="appearance-page">
    <div class="btn-wrapper">
      <el-button @click="handleSaveBtn" :class="!myOption ? 'disable_icon' : ''">保存</el-button>
      <!-- <el-button class="cancel-btn" @click="handleCancelBtn">取消</el-button> -->
    </div>
    <div class="system-wrapper">
      <div class="system-info">
        <div class="system-name common-div">
          <span class="label-name">系统名称</span>
          <el-input v-model.trim="operateForm.systemName" placeholder="桥梁群健康监测与评估云平台" maxlength="20" show-word-limit></el-input>
          <span class="description">{{ description1 }}</span>
        </div>
        <div class="main-nav common-div">
          <span class="label-name">主导航长度</span>
          <el-input v-model.trim="operateForm.mainNav" placeholder="20*字数+150"></el-input>
          <span class="description">{{ navDes }}</span>
        </div>
        <!-- <div class="system-nav common-div">
                    <span class="label-name">系统导航长度</span>
                    <el-input v-model.trim="operateForm.sysNav" placeholder="请设置系统导航长度"></el-input>
                    <span class="description">{{navDes}}</span>
                </div> -->
        <div class="system-logo common-div">
          <span class="label-name">系统LOGO</span>
          <el-upload class="upload-demo" :headers="{ token }" :on-success="onSysLogoSuccess" :before-upload="beforeSysLogoUpload" ref="upload1" accept=".jpg, .png" :action="baseURL + 'sys/sysImages/save/systems'" :show-file-list="false">
            <i class="el-icon-plus"></i>
          </el-upload>
          <img v-if="imgSrc1" :src="imgSrc1" alt="" />
          <span class="description">{{ systemLogoDes }}</span>
        </div>
        <div class="allview-logo common-div">
          <span class="label-name">总览背景图</span>
          <el-upload class="upload-demo" :headers="{ token }" :on-success="onAllViewSuccess" :before-upload="beforeAllViewUpload" ref="uploadAllView" accept=".jpg, .png" :action="baseURL + 'sys/sysImages/save/pandect'" :show-file-list="false">
            <i class="el-icon-plus"></i>
          </el-upload>
          <img v-if="allViewImgSrc" :src="allViewImgSrc" alt="" />
          <span class="description">{{ loginBackgroundDes }}</span>
        </div>
      </div>
      <!-- 主题换肤暂时不做 -->
      <!-- <div class="theme-color">
                <span class="label-name">主题换肤</span>
                <el-select v-model="operateForm.theme" placeholder="请选择主题" class="my-select">
                    <el-option v-for="item in themeOption" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
            </div> -->
    </div>
    <div class="login-page">
      <div class="title">
        <el-divider class="my-divider" direction="vertical"></el-divider>
        <span>个性化登录页</span>
      </div>
      <div class="setting-wrapper">
        <div class="login-setting">
          <div class="common-div">
            <span class="label-name">登录页背景图</span>
            <el-upload class="upload-demo" :headers="{ token }" :before-upload="beforeLoginBgUpload" :on-success="onLoginBgSuccess" ref="uploadLogin" accept=".jpg, .png" :action="baseURL + 'sys/sysImages/save/login'" :show-file-list="false">
              <i class="el-icon-plus"></i>
            </el-upload>
            <img v-if="imgSrc2" :src="imgSrc2" alt="" />
            <span class="description">{{ loginBackgroundDes }}</span>
          </div>
          <div class="common-div">
            <span class="label-name">企业LOGO</span>
            <el-upload class="upload-demo" :headers="{ token }" :before-upload="beforeComLogoUpload" :on-success="onCompLogoSuccess" ref="uploadCom" accept=".png" :action="baseURL + 'sys/sysImages/save/system'" :show-file-list="false">
              <i class="el-icon-plus"></i>
            </el-upload>
            <img v-if="imgSrc3" :src="imgSrc3" alt="" />
            <span class="description">{{ companyLogoDes }}</span>
          </div>
          <div class="common-div">
            <span class="label-name">网站LOGO</span>
            <el-upload class="upload-demo" :headers="{ token }" :before-upload="beforeWebsiteLogoUpload" :on-success="onWebsiteLogoSuccess" ref="uploadWebsite" accept=".png" :action="baseURL + 'sys/sysImages/save/web'" :show-file-list="false">
              <i class="el-icon-plus"></i>
            </el-upload>
            <img v-if="imgSrcWeb" :src="imgSrcWeb" alt="" />
            <span class="description">{{ companyLogoDes }}</span>
          </div>
          <div class="common-div">
            <span class="label-name">小标题</span>
            <el-input v-model.trim="operateForm.littleTitle" placeholder="桥梁健康管家" maxlength="15" show-word-limit></el-input>
            <span class="description">{{ description }}</span>
          </div>
          <div class="common-div">
            <span class="label-name">大标题</span>
            <el-input v-model.trim="operateForm.bigTitle" placeholder="桥梁群健康监测与评估云平台" maxlength="20" show-word-limit></el-input>
            <span class="description">{{ description1 }}</span>
          </div>
        </div>
        <div class="copyright-url">
          <div class="common-div">
            <span class="label-name">版权所有</span>
            <el-checkbox v-model="enableCopyright">启用</el-checkbox>
          </div>
          <div class="common-div margin-left-div" v-show="enableCopyright">
            <el-input v-model.trim="operateForm.copyright" placeholder="北京云庐科技有限公司" maxlength="30" show-word-limit></el-input>
            <span class="description">{{ description2 }}</span>
          </div>
          <div class="common-div">
            <span class="label-name"> <span class="required-tips">*</span>新URL地址 </span>
            <el-input v-model.trim="operateForm.urlAddress" placeholder="http://bridge.ylsas.com/"></el-input>
            <span class="description">{{ requiredDes }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="h5-page">
      <div class="title">
        <el-divider class="my-divider" direction="vertical"></el-divider>
        <span>H5登录页</span>
      </div>
      <div class="h5-wrapper">
        <div class="common-div h5-div">
          <span class="label-name">H5二维码</span>
          <el-checkbox v-model="enableH5">启用</el-checkbox>
        </div>
        <div class="common-div margin-left-div" v-show="enableH5">
          <el-upload class="upload-demo" :headers="{ token }" :before-upload="beforeH5Upload" :on-success="onH5CodeSuccess" ref="uploadH5" accept=".jpg, .png" :action="baseURL + 'sys/sysImages/save/code'" :show-file-list="false">
            <i class="el-icon-plus"></i>
          </el-upload>
          <img v-if="imgSrc4" :src="imgSrc4" alt="" />
          <span class="description">{{ qrCodeDes }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      token: window.sessionStorage.getItem("token"),
      operateForm: {
        // 系统名称
        systemName: "",
        // 主导航
        mainNav: "",
        // 系统导航
        sysNav: "",
        // 主题颜色
        theme: "0",
        // 小标题
        littleTitle: "",
        // 大标题
        bigTitle: "",
        // 版权
        copyright: "",
        // url
        urlAddress: ""
      },
      // 选填
      description: "选填, 不超过15个汉字",
      description1: "选填, 不超过20个汉字",
      description2: "选填, 不超过30个汉字",
      // 必填
      requiredDes: "必须, 谨慎填写",
      // 系统logo
      systemLogoDes: "格式: PNG、JPG, 高度: 32",
      // 企业logo
      companyLogoDes: "格式: PNG, 高度: 42",
      // 登录背景
      loginBackgroundDes: "格式: PNG、JPG, 大小: 1920*1080, 不超过2M",
      // h5二维码
      qrCodeDes: "格式: PNG、JPG, 大小: 180*180",
      // 提示
      tips: "(可见。否则隐藏)",
      // 导航
      navDes: "px",
      // 主题
      themeOption: [
        {
          id: "0",
          name: "默认"
        },
        {
          id: "1",
          name: "深蓝"
        },
        {
          id: "2",
          name: "浅绿"
        }
      ],
      // 启用版权
      enableCopyright: true,
      // 启用h5二维码
      enableH5: true,
      // 操作类型
      operateType: "",
      // 图片流 系统logo
      imgSrc1: "",
      // 图片id 系统logo
      src1Id: "",
      // 图片流 登录背景
      imgSrc2: "",
      // 图片id 登录背景
      src2Id: "",
      // 图片流 企业logo
      imgSrc3: "",
      // 图片id 企业logo
      src3Id: "",
      // 图片流 h5二维码
      imgSrc4: "",
      // 图片id h5二维码
      src4Id: "",
      // 图片流 网站logo
      imgSrcWeb: "",
      // 图片id 网站logo
      srcWebId: "",
      // 登录的用户
      userName: sessionStorage.getItem("name"),
      // 总览背景图
      allViewImgSrc: "",
      // 总览背景图 id
      allViewImgId: ""
    };
  },
  created() {
    this.getAppearanceData();
  },
  methods: {
    // 获取外观配置
    getAppearanceData() {
      // let address = window.location.href
      // let name = address.split('http://')[1].split('bridge')[0]
      let name = this.userName;
      this.$axios.get(`${this.baseURL}base/aspect/get/config?name=${name}`).then((res) => {
        if (res.status == "200") {
          console.log("外观配置----------------", res);
          this.operateForm.systemName = res.data.data.systemName;
          this.operateForm.littleTitle = res.data.data.smallTitle;
          this.operateForm.bigTitle = res.data.data.bigTitle;
          this.operateForm.copyright = res.data.data.copyright;
          this.enableCopyright = res.data.data.copyrightStatus == 1 ? true : false;
          this.operateForm.urlAddress = res.data.data.url;
          this.enableH5 = res.data.data.codeStatus == 1 ? true : false;
          this.imgSrc1 = `${this.baseURL}sys/sysImages/img/${res.data.data.systemLogoId}`;
          this.src1Id = res.data.data.systemLogoId;
          this.imgSrc2 = `${this.baseURL}sys/sysImages/img/${res.data.data.loginId}`;
          this.src2Id = res.data.data.loginId;
          this.imgSrc3 = `${this.baseURL}sys/sysImages/img/${res.data.data.firmLogoId}`;
          this.src3Id = res.data.data.firmLogoId;
          this.imgSrc4 = `${this.baseURL}sys/sysImages/img/${res.data.data.codeId}`;
          this.src4Id = res.data.data.codeId;
          this.imgSrcWeb = `${this.baseURL}sys/sysImages/img/${res.data.data.webId}`;
          this.srcWebId = res.data.data.webId;
          this.operateForm.mainNav = res.data.data.mainLength;
          this.allViewImgSrc = `${this.baseURL}sys/sysImages/img/${res.data.data.pandectId}`;
          this.allViewImgId = res.data.data.pandectId;
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 保存
    handleSaveBtn() {
      if (!this.myOption) {
        this.$message.error("您没有权限！");
        return;
      }
      // 新url地址必填
      if (!this.operateForm.urlAddress) {
        this.$message.warning("请设置地址");
        return;
      }
      let data = {
        bigTitle: this.operateForm.bigTitle, // 大标题
        codeId: this.src4Id, // h5二维码id
        codeStatus: this.enableH5 ? 1 : 0, // h5二维码状态
        copyright: this.operateForm.copyright, // 版权
        copyrightStatus: this.enableCopyright ? 1 : 0, // 版权状态
        loginId: this.src2Id, // 登录背景id
        pandectId: this.allViewImgId, // 总览背景图 id
        mainLength: this.operateForm.mainNav, // 主导航长度
        smallTitle: this.operateForm.littleTitle, // 小标题
        systemLength: this.operateForm.sysNav, // 系统导肮长度
        systemLogoId: this.src1Id, // 系统logo id
        systemName: this.operateForm.systemName, // 系统名称
        firmLogoId: this.src3Id, // 企业logo id
        webId: this.srcWebId, // 网站logo id
        url: this.operateForm.urlAddress // 新url地址
      };
      this.$axios.post(`${this.baseURL}base/aspect/save/config`, data).then((res) => {
        if (res.status == "200") {
          this.$message.success("设置成功");
        } else {
          this.$message.error(res.errMsg);
        }
      });
    },
    // 获取图片流
    getImage(id) {
      console.log("当前操作类型", this.operateType);
      switch (this.operateType) {
        case "sysLogo":
          this.imgSrc1 = `${this.baseURL}sys/sysImages/img/${id}`;
          this.src1Id = id;
          break;
        case "login":
          this.imgSrc2 = `${this.baseURL}sys/sysImages/img/${id}`;
          this.src2Id = id;
          break;
        case "comLogo":
          this.imgSrc3 = `${this.baseURL}sys/sysImages/img/${id}`;
          this.src3Id = id;
          break;
        case "webLogo":
          this.imgSrcWeb = `${this.baseURL}sys/sysImages/img/${id}`;
          this.srcWebId = id;
          break;
        case "qrCode":
          this.imgSrc4 = `${this.baseURL}sys/sysImages/img/${id}`;
          this.src4Id = id;
          break;
        case "allView":
          this.allViewImgSrc = `${this.baseURL}sys/sysImages/img/${id}`;
          this.allViewImgId = id;
          break;
      }
    },
    // 系统logo上传前
    beforeSysLogoUpload(file) {
      this.operateType = "sysLogo";
      console.log(file.type);
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "jpg" || fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.jpg/.png文件");
      }
      return isCorrectType;
    },
    // 上传系统logo
    onSysLogoSuccess(res, file, fileList) {
      // console.log(res, file, fileList)
      if (res.status == 200) {
        this.$message.success("上传成功");
        this.getImage(res.data);
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 总览背景图上传前
    beforeAllViewUpload(file) {
      this.operateType = "allView";
      console.log(file.type);
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "jpg" || fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.jpg/.png文件");
      }
      return isCorrectType;
    },
    // 上传总览背景图
    onAllViewSuccess(res, file, fileList) {
      console.log(res);
      if (res.status == 200) {
        this.$message.success("上传成功");
        this.getImage(res.data);
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 登录页背景图上传前
    beforeLoginBgUpload(file) {
      this.operateType = "login";
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "jpg" || fileType === "png";
      // const isCorrectScale = file.size / 1024 / 1024 < 2
      const isCorrectScale = true;
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.jpg/.png文件");
      }
      if (!isCorrectScale) {
        this.$message.error("文件不得超过2M");
      }
      return isCorrectType && isCorrectScale;
    },
    // 上传登录页背景图
    onLoginBgSuccess(res, file, fileList) {
      // console.log(res, file, fileList)
      if (res.status == 200) {
        this.$message.success("上传成功");
        this.getImage(res.data);
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 企业logo上传前
    beforeComLogoUpload(file) {
      this.operateType = "comLogo";
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.png文件");
      }
      return isCorrectType;
    },
    // 上传企业logo
    onCompLogoSuccess(res, file, fileList) {
      // console.log(res)
      if (res.status == 200) {
        this.$message.success("上传成功");
        this.getImage(res.data);
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 网站logo上传前
    beforeWebsiteLogoUpload(file) {
      this.operateType = "webLogo";
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.png文件");
      }
      return isCorrectType;
    },
    // 上传网站logo
    onWebsiteLogoSuccess(res, file, fileList) {
      // console.log(res)
      if (res.status == 200) {
        this.$message.success("上传成功");
        this.getImage(res.data);
      } else {
        this.$message.error(res.errMsg);
      }
    },
    // 二维码上传前
    beforeH5Upload(file) {
      this.operateType = "qrCode";
      const fileType = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isCorrectType = fileType === "jpg" || fileType === "png";
      if (!isCorrectType) {
        this.$message.error("文件格式不正确，请上传.jpg/.png文件");
      }
      return isCorrectType;
    },
    // 上传二维码
    onH5CodeSuccess(res, file, fileList) {
      // console.log(res)
      if (res.status == 200) {
        this.$message.success("上传成功");
        this.getImage(res.data);
      } else {
        this.$message.error(res.errMsg);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.appearance-page {
  padding: 15px;
  // background: linear-gradient(180deg, rgba(4, 85, 213, 0.1) 1%, rgba(19, 18, 68, 0.65));
  // border: 1px solid #2667db;
  .btn-wrapper {
    text-align: right;
    .cancel-btn {
      margin-left: 20px;
      background: none;
      border: 1px solid #2667db;
    }
  }
  .system-wrapper {
    display: flex;
    .system-info {
      width: 50%;
      line-height: 4;
    }
    .theme-color {
      width: 50%;
    }
  }
  .login-page {
    margin: 20px 0;
    .title {
      margin: 20px 0;
      .my-divider {
        width: 2px;
        height: 7px;
        background: #2667db;
        margin-right: 15px;
      }
    }
    .setting-wrapper {
      display: flex;
      .login-setting {
        width: 50%;
        line-height: 4;
      }
      .copyright-url {
        width: 50%;
        line-height: 4;
      }
    }
  }
  .h5-page {
    margin: 20px 0;
    .title {
      margin: 20px 0;
      .my-divider {
        width: 2px;
        height: 7px;
        background: #2667db;
        margin-right: 15px;
      }
    }
    .h5-wrapper {
      line-height: 4;
    }
  }
}
.common-div {
  display: flex;
  align-items: center;
}
.margin-left-div {
  width: 100%;
  margin-left: 115px;
}
.label-name {
  width: 100px;
  text-align: right;
  font-size: 14px;
  margin-right: 20px;
  .required-tips {
    color: red;
  }
}
.tips-text {
  color: #d63641;
  margin-left: 20px;
}
.el-input {
  width: 50%;
  height: 34px;
  line-height: 34px;
  background: #0c265a;
  border-radius: 20px;
}
.description {
  font-size: 14px;
  color: #ccdaff;
  margin-left: 15px;
}
.upload-demo {
  width: 70px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #192c52;
  &:hover {
    cursor: pointer;
  }
  ::v-deep .el-upload.el-upload--text {
    width: 100%;
    height: 100%;
    i {
      color: #3d6391;
    }
  }
}
img {
  height: 42px;
  margin-left: 20px;
}
::v-deep .my-select .el-input.el-input--suffix {
  height: 34px;
  input {
    background: #0c265a !important;
  }
}
::v-deep span.el-checkbox__label {
  font-size: 14px;
}
::v-deep .el-checkbox__inner {
  background-color: #2667db !important;
  border-color: #2667db !important;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #2772f0 !important;
  border-color: #2772f0 !important;
}
::v-deep .el-input__count-inner {
  background: transparent !important;
}
</style>
